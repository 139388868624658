import React, { useState } from "react";
import { AiOutlineBars } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
import arrow from "../../assets/arrow.svg"
import logo from "../../assets/logo.svg"

import "./Navbar.css";

const Navbar = ({ navnav }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [mask, setMask] = useState(false)

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setMask(!mask);
  };

  return (
    <>
      <nav className={navnav == true ? "navbar plr" : "navbar plr nav_about_mobile"}>
        <div className="logo flex">
          <a href="/">
            <img src={logo} alt="logo" width="150" />
          </a>
          <a href="#" className="flex"> TRIPALINK REAL ESTATE
            {/*<img src={arrow}/> */}
          </a>
        </div>
        <menu>
          <ul className="nav-links" id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/communities">Communities</a>
            </li>
            <li>
              <a href="/press">Press</a>
            </li>
            {/*<li>
            <a href="/study">Case Study</a>
          </li>*/}
            <li>
              <a href="/contact">Contact Us</a>
            </li>

            {/* <li>
            <a href="#" className="btn btn-dark">
              Get Started
            </a>
          </li> */}
          </ul>
        </menu>
        <div className="menu-icons" onClick={toggleMenu}>
          {showMenu ? (
            <RiCloseLine color="#000" size={30} />
          ) : (
            <AiOutlineBars color="#000" size={27} />
          )}
        </div>
      </nav>
      <div className={showMenu ? "mob-mask" : ""}></div>
    </>
  );
};

export default Navbar;
