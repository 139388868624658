import './Contact.css';
import ubiimg from "../../assets/ubi.svg"
import emailimg from "../../assets/email.svg"
import {
  Navbar,
  Footer,
} from "../../components";

const Contact = () => {

  return (
    <>
      <Navbar navnav={true} />
      <div className="ctc">
        <div className="center w-100 ptb">
          <div className="container  center c-white contact-container">
            <h1 className="c-black h1_contact">Ready to Speak With Us?</h1>
            <p className='with'>With 40+ completed ground-up projects averaging more than 35% IRR for investors, Tripalink is a leading multifamily developer in the co-living and student housing space. Please sign up to get more information about our investment opportunities!</p>
            <a href="https://tripalink.junipersquare.com/portal/view_lead_capture_form/NDBlNTY4ZTIwMjAwYjk0YTVhZjZjYjk4YTE0ZWUwOGYzYTQ5NDRhNmdBV1ZEUUFBQUFBQUFBQW9UVmtIVFJrTFRrNkpkSlF1" className="c-white button contact-but ">Invest with Us</a>
            <p className="p-xxs-contact mt">*This is not an offer of securities, which is made only through our Private Placement Memorandum to qualified investors. Investments in securities and real estate are subject to risks of loss, as further described in our Private Placement Memorandum. Historical results are not a guarantee of future profits. See our Private Placement Memorandum for full description of terms and risk factors.</p>
            <div className="contact-email">
              <img className="" src={ubiimg} width="40" />
              <h2 className="p-xs">
                <a href="https://www.google.com/maps/place/800+S+Harvard+Blvd,+Los+Angeles,+CA+90005,+EE.+UU./data=!4m2!3m1!1s0x80c2b8815b7fd573:0x21796904410ab1f3?sa=X&amp;ved=2ahUKEwjsl5Cfr-L5AhVIlJUCHSFMA64Q8gF6BAgbEAE" target="_blank">800 S Harvard Ave, Suite Office <br />Los Angeles, California 90005</a>
              </h2>
            </div>
            <div className="contact-email">
              <img className="" src={emailimg} width="40" />
              <h2 className="p-xs">
                <a href="mailto:realestate@tripalink.com" target="_blank">realestate@tripalink.com </a>
              </h2>
            </div>
          </div>
        </div>
      </div>

      {/* <div className=" container">
        <div className="grid-item p-grid initial">
          <p className="p-xs-contact mb-5">With 40+ completed ground-up projects averaging more than 35% IRR for investors, Tripalink is a leading multifamily developer in the co-living and student housing space. Please sign up to get more information about our investment opportunities!</p>
          <div className="center_mobile">
            <a href="https://tripalink.junipersquare.com/portal/view_lead_capture_form/NDBlNTY4ZTIwMjAwYjk0YTVhZjZjYjk4YTE0ZWUwOGYzYTQ5NDRhNmdBV1ZEUUFBQUFBQUFBQW9UVmtIVFJrTFRrNkpkSlF1" className="c-white button">Invest With Us</a>
          </div>
          <p className="p-xxs-contact mt">*This is not an offer of securities, which is made only through our Private Placement Memorandum to qualified investors. Investments in securities and real estate are subject to risks of loss, as further described in our Private Placement Memorandum. Historical results are not a guarantee of future profits. See our Private Placement Memorandum for full description of terms and risk factors.</p>
        </div>
        <div>
          <div className="align-center row-space ">
            <img className="m-rl ml-mobile-0" src={ubiimg} width="40" />
            <h2 className="p-xs">
              <a href="https://www.google.com/maps/place/800+S+Harvard+Blvd,+Los+Angeles,+CA+90005,+EE.+UU./data=!4m2!3m1!1s0x80c2b8815b7fd573:0x21796904410ab1f3?sa=X&amp;ved=2ahUKEwjsl5Cfr-L5AhVIlJUCHSFMA64Q8gF6BAgbEAE" target="_blank">800 S Harvard Ave, Suite Office <br />Los Angeles, California 90005</a>
            </h2>
          </div>
          <div className="align-center row-space ptb">
            <img className="m-rl ml-mobile-0" src={emailimg} width="40" />
            <h2 className="p-xs">
              <a href="mailto:development@tripalink.com" target="_blank">development@tripalink.com </a>
            </h2>
          </div>
        </div> */}
      {/* <div className=" p-grid grid-item">
          <div className="container footer-mobile-contact">
            <div className="align-center row-space ">
              <img className="m-rl ml-mobile-0" src={ubiimg} width="40" />
              <h2 className="p-xs">
                <a href="https://www.google.com/maps/place/800+S+Harvard+Blvd,+Los+Angeles,+CA+90005,+EE.+UU./data=!4m2!3m1!1s0x80c2b8815b7fd573:0x21796904410ab1f3?sa=X&amp;ved=2ahUKEwjsl5Cfr-L5AhVIlJUCHSFMA64Q8gF6BAgbEAE" target="_blank">800 S Harvard Ave, Suite Office <br />Los Angeles, California 90005</a>
              </h2>
            </div>
            <div className="align-center row-space ptb">
              <img className="m-rl ml-mobile-0" src={emailimg} width="40" />
              <h2 className="p-xs">
                <a href="mailto:development@tripalink.com" target="_blank">development@tripalink.com </a>
              </h2>
            </div>
          </div>
        </div> */}
      {/* </div> */}
      <Footer hiddden={false} />
    </>
  );
};

export default Contact;