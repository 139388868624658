import './About.css';
import { useState } from "react";
import ReactDOM from "react-dom/client";
import {
  Navbar,
  Footer,
} from "../../components";

const img = require('../../assets/line.png')
const list = [
  { year: 2016, title: 'Tripalink Founded', message: 'Leveraging tech-driven operation and innovative real estate development ability, Tripalink is building the next generation residential brand.', img: require('../../assets/about/about1.png') },
  { year: 2017, title: 'First Community Envisioned', message: 'Tripalink acquired its first property and planted the seed of a next-gen community.', img: require('../../assets/about/about2.png') },
  { year: 2018, title: 'First Community In Operation', message: 'The first community was delivered and sparked out wilder imaginations.', img: require('../../assets/about/about3.png') },
  { year: 2019, title: 'Tripalink V-living 2.0', message: 'With complete new design throughout the building, Tripalink Studio proudly presented 2nd gen communities.', img: require('../../assets/about/about4.png') },
  { year: 2020, title: 'Keep Innovating', message: 'We never stopped our innovation even during pandemic. ', img: require('../../assets/about/about5.png') },
  { year: 2021, title: 'Tripalink V-living 3.0', message: 'One of its kind.', img: require('../../assets/about/about6.png') },
  { year: 2022, title: 'A New Chapter', message: 'Taking inspiration from the past, set the sail again!', img: require('../../assets/about/about7.png') },
]

const About = () => {
  // const [ocultar0, setOcultar0] = useState(true);
  // const [ocultar1, setOcultar1] = useState(false);
  // const [ocultar2, setOcultar2] = useState(false);
  // const [ocultar3, setOcultar3] = useState(false);

  // const [hide0, setHide0] = useState(true);
  // const [hide1, setHide1] = useState(false);

  // const ocultar = (id) => {
  //   setOcultar0(false)
  //   setOcultar1(false)
  //   setOcultar2(false)
  //   setOcultar3(false)

  //   if (id == 0) {
  //     setOcultar0(true)
  //   }
  //   if (id == 1) {
  //     setOcultar1(true)
  //   }
  //   if (id == 2) {
  //     setOcultar2(true)
  //   }
  //   if (id == 3) {
  //     setOcultar3(true)
  //   }
  // }

  // const hide = (id) => {
  //   setHide0(false)
  //   setHide1(false)

  //   if (id == 0) {
  //     setHide0(true)
  //   }
  //   if (id == 1) {
  //     setHide1(true)
  //   }
  // }

  return (
    <>
      <Navbar navnav={false} />
      <div className="abt abt_mobile">
        <div className="center w-100">
          <div className="container home center c-white">
            <h1 className="ptb">About Tripalink</h1>
            <img className="show_mobile_about" src={require('../../assets/abouthome.png')} />
          </div>
        </div>
      </div>

      <div className="wwa">
        <div className='time-box'>
          {list.map(({ year, title, message, img }, key) =>
            <div key={year} className='time-line' style={{ borderImage: key === list.length - 1 ? "linear-gradient(190deg, rgba(255, 255, 255, 0) 0%, #000 20%, rgba(255, 255, 255, 0) 99%) 2 2 2 2" : '' }}>
              <div style={{ color: key === list.length - 1 ? '#E74F4F' : '#000' }} className='time-year'>{year}</div>
              <div className='time-item'>
                <div className='item-top'><img src={img} alt='' /></div>
                <div className='item-right'>
                  <h2>{title}</h2>
                  <p>{message}</p>
                </div>
                <div className='time-vertical' />
                <div className='time-spot' />
              </div>
            </div>)}
        </div>
        {/* <div className="row-center status center p-2">
          <div className={(hide0) ? "active p-12-ab " : "pointer p-12-ab "}>
            <h2 onClick={() => hide(0)}>Why Tripalink</h2>
          </div>
          <div className={(hide1) ? "active p-12-ab " : "pointer p-12-ab "}>
            <h2 onClick={() => hide(1)}>Leadership</h2>
          </div>
        </div>

        {hide0 && <div>
          <div className="justify-center flex center ">
            <div className="p-2 ">
              <h1 className={(ocultar0) ? "date__color year__number" : " year__number pointer"} onClick={() => ocultar(0)}>2016</h1>
              {ocultar0 && <img src={require('../../assets/line.png')} width="6" height="112px" />}
            </div>
            <div className="p-2 ">
              <h1 className={(ocultar1) ? "date__color year__number" : " year__number pointer"} onClick={() => ocultar(1)}>2017</h1>
              {ocultar1 && <img src={require('../../assets/line.png')} width="6" height="112px" />}
            </div>
            <div className="p-2 ">
              <h1 className={(ocultar2) ? "date__color year__number" : " year__number pointer"} onClick={() => ocultar(2)}>2020</h1>
              {ocultar2 && <img src={require('../../assets/line.png')} width="6" height="112px" />}
            </div>
            <div className="p-2 ">
              <h1 className={(ocultar3) ? "date__color year__number" : " year__number pointer"} onClick={() => ocultar(3)}>Now</h1>
              {ocultar3 && <img src={require('../../assets/line.png')} width="6" height="112px" />}
            </div>
          </div>

          {ocultar0 && <h2 className="container mb center year__stories">Founded in <b className="date__color">2016</b>, Tripalink is building the world’s leading Co-Living company to serve 1.8 billion Gen-Z and younger millennials. Tripalink is leveraging tech- driven and data-oriented operation and innovative real estate development ability to deliver an affordable and enjoyable rental experience.</h2>}

          {ocultar1 && <h2 className="container mb center year__stories">Built our first co-living apartment near USC </h2>}

          {ocultar2 && <h2 className="container mb center year__stories">Entered the Seattle and San Francisco market. Completed 11 ground-up projects</h2>}

          {ocultar3 && <h2 className="container mb center year__stories">Entered the Austin market</h2>}

          <div className="grid-container container">
            <div className="grid-item p-grid living">
              <img src={require('../../assets/r1.png')} width="300px" />
              <p className="about__card">Proven Track Record</p>
              <p className="p-xs">To continually improve, we have to walk in our customers’ shoes to understand their challenges. Always ask: Are we doing what’s best to help our customers?</p>
            </div>

            <div className=" p-grid grid-item living">
              <img src={require('../../assets/r2.png')} width="300px" />
              <p className="about__card">Primarily Focus</p>
              <p className="p-xs">Create new things when you see an opportunity, to achieve bigger goals.</p>
            </div>

            <div className=" p-grid grid-item living">
              <img src={require('../../assets/r3.png')} width="300px" />
              <p className="about__card">Co-living Home Design Philosophy</p>
              <p className="p-xs">Achieves perfect balance between privacy and sociability and maximizes the rent per sqft, thus boosting the return of projects.</p>
            </div>

            <div className=" p-grid grid-item living">
              <img src={require('../../assets/r4.png')} width="300px" />
              <p className="about__card">US Rental Market</p>
              <p className="p-xs">Always consider the outcome, and how it can benefit the customer and the company.</p>
            </div>

            <div className=" p-grid grid-item living">
              <img src={require('../../assets/r5.png')} width="300px" />
              <p className="about__card">Tech Enabled Operations</p>
              <p className="p-xs">Speed is key to winning a battle. Be thoughtful, but don't wait until next week to do something you could finish today. </p>
            </div>

            <div className=" p-grid grid-item living">
              <img src={require('../../assets/r6.png')} width="300px" />
              <p className="about__card">Experienced Management Team</p>
              <p className="p-xs">Love what you do, and get excited about your work and the people you work with each day (even Monday!).</p>
            </div>
          </div>

        </div>}
        {hide1 && <div className="grid-container container">
          <div className="grid-item p-grid living">
            <div className="left">
              <img src={require('../../assets/li.png')} className="founder_img_space" />
            </div>
            <p>Founder<br />
              CEO  |   Donghao Li</p>
            <p className="p-xs">
              <li>5+ Years Management Experience</li>
              <li>GYIC 2019 Influential Youth Leader Award</li>
              <li>2019 Forbes China 30 under 30</li>
            </p>

          </div>
          <div className="grid-item p-grid living">
            <div className="left">
              <img src={require('../../assets/liang.png')} className="founder_img_space" />
            </div>
            <p>Founder<br />
              President  |   Patrick Liang </p>
            <p className="p-xs">
              <li>10 Years Real Estate Industry Experience</li>
              <li>23 Years Management Background</li>
              <li>18 Years Entrepreneur Experience</li>
            </p>
          </div>

          <div className="grid-item p-grid living">
            <div className="left">
              <img src={require('../../assets/feeny.png')} className="founder_img_space" />
            </div>
            <p>Advisory<br />
              CBRE  |   Curtis F. Feeny</p>
            <p className="p-xs">
              <li>1981 Harvard MBA Alumnus</li>
              <li>20+ Years Real Estate Experience</li>
              <li>20+ Companies Board Member</li>
              <li>20+ Years Venture Capital Experience</li>
            </p>
          </div>
        </div>} */}
      </div>
      <Footer hiddden={true} />
    </>
  );
};

export default About;