import React from "react";
import ubiimg from "../../assets/ubi.svg"
import emailimg from "../../assets/email.svg"
import "./Footer.css";
import logocasa from "../../assets/lofo.svg";
import tw from "../../assets/tw.svg";
import insta from "../../assets/insta.svg"
import yt from "../../assets/yt.svg"
import fb from "../../assets/fb.svg"
import ln from "../../assets/ln.svg"

import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaFax,
  FaEnvelope,
  FaGlobe,
} from "react-icons/fa";

const Footer = ({ hiddden }) => {
  return (
    <>
      {hiddden && <section className="ptb center mt" style={{ background: '#E0E2EF33' }}>
        <div className="ptb">
          <h1>Ready to speak with us?</h1>
          <div className="row-space container">
            <div className="align-center row-space ptb">
              <img className="mr-footer" src={ubiimg} width="40" />
              <h2 className="p-xs">
                <a href="https://www.google.com/maps/place/800+S+Harvard+Blvd,+Los+Angeles,+CA+90005,+EE.+UU./data=!4m2!3m1!1s0x80c2b8815b7fd573:0x21796904410ab1f3?sa=X&ved=2ahUKEwjsl5Cfr-L5AhVIlJUCHSFMA64Q8gF6BAgbEAE" target="_blank">
                  800 S Harvard Ave, Suite Office <br />
                  Los Angeles, California 90005</a></h2>
            </div>
            <div className="align-center row-space ptb">
              <img className="mr-footer" src={emailimg} width="40" />
              <h2 className="p-xs">
                <a href="mailto:development@tripalink.com" target="_blank">realestate@tripalink.com </a>
              </h2>
            </div>
          </div>
        </div>
        <div className="center_mobile">
          <a href="https://tripalink.junipersquare.com/portal/view_lead_capture_form/NDBlNTY4ZTIwMjAwYjk0YTVhZjZjYjk4YTE0ZWUwOGYzYTQ5NDRhNmdBV1ZEUUFBQUFBQUFBQW9UVmtIVFJrTFRrNkpkSlF1" className="c-white button mtb">Invest with Us</a></div>
        <div className="space__button__footer" />
      </section>}

      <section id="footer" className="new-footer">
        <div className="">
          <div className="company">Company</div>
          <div className="footer-nav">
            <a href="/about">About</a>
            <a href="/communities">Communities</a>
            <a href="/press">Press</a>
            <a href="/contact">Contact Us</a>
            <a href="https://tripalink.com">Tripalink</a>
          </div>
        </div>
        <div className="footer-right">
          <img className="log-img" src={logocasa} />
          <div className="footer-box">
            <div className="flex">
              <div className="m1">
                <img width="25" height="25" src={fb} />
              </div>
              <div className="m1">
                <img width="25" height="25" src={tw} />
              </div>
              <div className="m1">
                <img width="25" height="25" src={insta} />
              </div>
              <div className="m1">
                <img width="25" height="25" src={ln} />
              </div>
              <div className="m1">
                <img width="25" height="25" src={yt} />
              </div>
            </div>
            <p className="u-text-small">©2022 Tripalink Inc. All Rights Reserved</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
