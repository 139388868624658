import './Study.css';
import {
  Navbar,
  Footer,
} from "../../components";

const Study = () => {

  return (
    <>
      <Navbar />
      <div className="st">
        <div className="center w-100">
          <div className="container home center c-white">
            <h1>Project Case Study</h1>
          </div>
        </div>
      </div>

      <div className="wwa">
        <div className="container ptb">
          <div className="row-space p-2">
            <div className="column ptb">
              <h2 className="date__color">Highlight</h2><br />
              <h1>Case Study abcde</h1>
              <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper volutpat mauris parturient nunc scelerisque eget.</h2>
            </div>
            <div className="column p-2 center">
              <img src={require('../../assets/pro1.png')} width="100%" style={{borderRadius:'20px'}}  />
            </div>
          </div>

          <div className="row-space ptb">

            <div className="column p-2 center order">
              <img src={require('../../assets/pro2.png')} width="100%" style={{borderRadius:'20px'}} />
            </div>

            <div className="column p-2">
              <h2 className="date__color">Highlight</h2><br />
              <h1>Case Study abcde</h1>
              <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper volutpat mauris parturient nunc scelerisque eget.</h2>
            </div>
            
          </div>
        </div>
      </div>  
      <Footer hiddden={true} />
    </>
  );
};

export default Study;