import './Portfolio.css';
import closeModal from "../../assets/close.svg"
import { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom/client";
import arrow from "../../assets/arrow.svg"
import line from "../../assets/line.svg"
import Pagination from '@mui/material/Pagination';

import {
  Navbar,
  Footer,
} from "../../components";

import json from './list.json';

var moment = require('moment');

const Portfolio = () => {
  const [hidemodal, setHidemodal] = useState(false);
  const [color, setColor] = useState("arrow-mobile");
  const pageCount = 12
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)

  useEffect(() => {
    setData(json.slice(0, pageCount))
  }, [])

  useEffect(() => {
    setData(json.slice((page - 1) * pageCount, page * pageCount))
  }, [page])

  const onChange = (_, page) => {
    setPage(page)
  }

  const [modalData, setModalData] = useState({});
  const [currentList, setCurrentList] = useState('los-angeles');

  const viewModal = (item) => {
    setModalData(item)
    setHidemodal(true)
  }

  const clickmob = (id) => {
    console.log('entro')
    if (id === 0) {
      setColor("arrow-mobile rotate")
    }
    if (id === 1) {
      setColor("arrow-mobile")
    }
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setHidemodal(false)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="wwa">
        <div className="flex status center plr mb-3 ">
          {/* <a className={"pointer f-portfolio border-radius m1 " + (currentList == 'los-angeles' ? 'active' : '')} onClick={() => {
              setCurrentList('los-angeles')
            }} href="#action-sheet">
              <h2>Los Angeles</h2>
              <img src={arrow} className="arrow-mobile" />
            </a>
            <a className={"pointer f-portfolio border-radius m1 " + (currentList == 'bay-area' ? 'active' : '')} onClick={() => {
              setCurrentList('bay-area')
            }} href="#action-sheet">
              <h2>Bay Area</h2>
              <img src={arrow} className="arrow-mobile" />
            </a>
            <a className={"pointer f-portfolio border-radius m1 " + (currentList == 'seattle' ? 'active' : '')} onClick={() => {
              setCurrentList('seattle')
            }} href="#action-sheet">
              <h2>Seattle</h2>
              <img src={arrow} className="arrow-mobile" />
            </a> */}
          <div id="action-sheet">
            <a href="#ciaone" className="backdrop"></a>
            <div className="dialog">
              <div className="dialog__title">
                <a className="dialog__title__close" href="#"><img src={line} /></a>
              </div>
              <div className="dialog__title"> Location </div>
              <div>
                <div className="dialog__body">
                  <a className={"btn border-radius m1 " + (currentList == 'los-angeles' ? 'active' : '')} onClick={() => {
                    setCurrentList('los-angeles')
                  }} href="#">Los Angeles</a>
                  <a className={"btn border-radius m1 " + (currentList == 'bay-area' ? 'active' : '')} onClick={() => {
                    setCurrentList('bay-area')
                  }} href="#">Bay Area</a>
                  <a className={"btn border-radius m1 " + (currentList == 'seattle' ? 'active' : '')} onClick={() => {
                    setCurrentList('seattle')
                  }} href="#">Seattle</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid-container-portfolio mt-3">
          {/* {json.filter((e) => e.Key == currentList).map((item, key) => { */}
          {data.map((item, key) => {
            if (item.Images.length > 0) {
              return (
                <div key={key} onClick={() => viewModal(item)} className="grid-item p-grid portfolio-living" style={{ cursor: 'pointer' }}>
                  <img src={require('../../assets/communities/' + item.Images[0])} width="300px" style={{ borderRadius: 10 }} />
                  <p>{item.Name}</p>
                </div>
              )
            }
          })}
          <div className='list-item' />
          <div className='list-item' />
          <div className='list-item' />
          <div className='list-item' />
        </div>
        <div className='pagination'>
          <Pagination count={2} onChange={onChange} />
        </div>
        {hidemodal && <div className="modal" onClick={() => setHidemodal(false)}>
          <div className="body-modal-portfolio" onClick={(e) => {
            e.stopPropagation()
          }}>
            <img onClick={() => setHidemodal(false)} src={closeModal} className="close-modal pointer" />
            {modalData.Images[0] != '' && <img src={require('../../assets/communities/' + modalData.Images[0])} style={{ width: '100%', maxWidth: 955 }} />}
            <div className='portfolio-title'>
              <div>
                <h2>{modalData.Name}</h2>
                <p style={{ fontSize: 14, color: '#050A22' }}>{modalData.Location}</p>
              </div>
              <div className='portfolio-right'>
                <div className='status'>Status</div>
                <div className='entitlement'>{modalData.status}</div>
              </div>
            </div>
            {/* <div className="flex"> */}
            {/* <div style={{ flex: 1 }}>
                <p style={{ fontSize: 16, fontWeight: 600, color: '#9497A7', marginBottom: 8 }}>Est. Construction Completion</p>
                <p style={{ fontSize: 16, fontWeight: 600, color: '#050A22' }}>{moment(modalData['Est. Construction Completion']).format('MMMM YYYY')}</p>
              </div> */}
            {/* <div style={{ flex: 1 }}>
                <p style={{ fontSize: 16, fontWeight: 600, color: '#9497A7', marginBottom: 8 }}>Type</p>
                <p style={{ fontSize: 16, fontWeight: 600, color: '#050A22' }}>{modalData.Story}-story Apartment</p>
              </div>
              <div style={{ flex: 1 }}>
                <p style={{ fontSize: 16, fontWeight: 600, color: '#9497A7', marginBottom: 8 }}>Total Rooms</p>
                <p style={{ fontSize: 16, fontWeight: 600, color: '#050A22' }}>{modalData['Total Rooms']}</p>
              </div> */}
            {/* </div> */}
          </div>
          <div style={{ height: 62 }}>
            asd123
          </div>
        </div>}
      </div>
      <Footer hiddden={true} />
    </>
  );
};

export default Portfolio;