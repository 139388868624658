import './Portfolio.css';
import closeModal from "../../assets/close.svg"
import { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom/client";
import arrow from "../../assets/arrow.svg"
import line from "../../assets/line.svg"
import Pagination from '@mui/material/Pagination';
// import { createStyles, makeStyles } from '@mui/styles';

import {
  Navbar,
  Footer,
} from "../../components";

const list = [
  { title: 'Six-story, 33-unit development planned at 4722 Elmwood in Larchmont', img: require('../../assets/press/1.jpeg'), url: 'https://la.urbanize.city/post/six-story-33-unit-development-planned-4722-elmwood-larchmont' },
  { title: 'Skinny apartment building to replace 1920s house near USC', img: require('../../assets/press/2.jpeg'), url: 'https://la.urbanize.city/post/exposition-park-1128-37th-drive' },
  { title: 'Co-Living Developer Proposes Townhomes in West Adams', img: require('../../assets/press/3.jpeg'), url: 'https://la.urbanize.city/post/co-living-developer-proposes-townhomes-west-adams' },
  { title: 'Tripalink Looks Beyond Coliving Properties', img: require('../../assets/press/4.jpeg'), url: 'https://labusinessjournal.com/real-estate/tripalink-looks-beyond-coliving-apartments/' },
  { title: 'Tripalink Expands Tenant-focused Strategy through Keystone Properties NW Acquisition', img: require('../../assets/press/5.webp'), url: 'https://news.theregistryps.com/tripalink-expands-tenant-focused-strategy-through-keystone-properties-nw-acquisition/' },
  { title: 'Tripalink, Tech-driven Residential Property Operator, Expands its Market in Seattle', img: require('../../assets/press/6.jpeg'), url: 'https://www.prnewswire.com/news-releases/tripalink-tech-driven-residential-property-operator-expands-its-market-in-seattle-with-the-acquisition-of-keystone-properties-nw-301371901.html' },
  { title: 'Residential Brand Tripalink Raises $30M to Fuel Technology and Growth', img: require('../../assets/press/7.png'), url: 'https://www.techtimes.com/articles/268141/20211117/residential-brand-tripalink-raises-30m-to-fuel-technology-and-growth.htm' },
  { title: `How Donghao Li's Co- Living Startup, Tripalink, Makes Big City Living Affordable`, img: require('../../assets/press/8.png'), url: 'https://www.newswire.com/news/how-donghao-lis-co-living-startup-tripalink-make-big-cities-living-21029808' },
  { title: 'How Tripalink Is Elevating Life For Young Renters', img: require('../../assets/press/9.jpeg'), url: 'https://www.forbes.com/sites/amandalauren/2022/03/10/how-tripalink-is-elevating-life-for-young-renters/?sh=10b51c2d1c52' },
  { title: 'Tripalink proves co-living can be a luxurious experience that renters are excited about.', img: require('../../assets/press/10.jpeg'), url: 'https://laconfidentialmag.com/tripalink-proves-co-living-can-be-a-luxurious-experience-that-renters-are-excited-about' },
  { title: `Tripalink Ranks on the Forbes America's Best Startup Employers 2022 List`, img: require('../../assets/press/11.png'), url: 'https://www.prnewswire.com/news-releases/tripalink-ranks-on-the-forbes-americas-best-startup-employers-2022-list-301499388.html' },
]

const Portfolio = () => {
  const [hidemodal, setHidemodal] = useState(false);
  const [color, setColor] = useState("arrow-mobile");
  const pageCount = 9
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)

  // const useStyles = makeStyles(() => createStyles({
  //   page: {
  //     padding: '0',
  //   },
  //   icon: {
  //     // borderRadius: '16px',
  //     // padding: '0 6px',
  //     // backgroundColor: '#b74d3e',
  //     width: '24px',
  //     height: '24px',
  //     // color: '#fff',
  //   },
  // }));

  // const classes = useStyles();

  useEffect(() => {
    setData(list.slice(0, pageCount))
  }, [])

  useEffect(() => {
    setData(list.slice((page - 1) * pageCount, page * pageCount))
  }, [page])

  const onChange = (_, page) => {
    setPage(page)
  }

  const [modalData, setModalData] = useState({});
  const [currentList, setCurrentList] = useState('los-angeles');

  const viewModal = (item) => {
    setModalData(item)
    setHidemodal(true)
  }

  const clickmob = (id) => {
    console.log('entro')
    if (id === 0) {
      setColor("arrow-mobile rotate")
    }
    if (id === 1) {
      setColor("arrow-mobile")
    }
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setHidemodal(false)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="wwa">
        <div className="flex status center plr mb-3 ">
          {/* <a className={"pointer f-portfolio border-radius m1 " + (currentList == 'los-angeles' ? 'active' : '')} onClick={() => {
              setCurrentList('los-angeles')
            }} href="#action-sheet">
              <h2>Los Angeles</h2>
              <img src={arrow} className="arrow-mobile" />
            </a>
            <a className={"pointer f-portfolio border-radius m1 " + (currentList == 'bay-area' ? 'active' : '')} onClick={() => {
              setCurrentList('bay-area')
            }} href="#action-sheet">
              <h2>Bay Area</h2>
              <img src={arrow} className="arrow-mobile" />
            </a>
            <a className={"pointer f-portfolio border-radius m1 " + (currentList == 'seattle' ? 'active' : '')} onClick={() => {
              setCurrentList('seattle')
            }} href="#action-sheet">
              <h2>Seattle</h2>
              <img src={arrow} className="arrow-mobile" />
            </a> */}
          <div id="action-sheet">
            <a href="#ciaone" className="backdrop"></a>
            <div className="dialog">
              <div className="dialog__title">
                <a className="dialog__title__close" href="#"><img src={line} /></a>
              </div>
              <div className="dialog__title"> Location </div>
              <div>
                <div className="dialog__body">
                  <a className={"btn border-radius m1 " + (currentList == 'los-angeles' ? 'active' : '')} onClick={() => {
                    setCurrentList('los-angeles')
                  }} href="#">Los Angeles</a>
                  <a className={"btn border-radius m1 " + (currentList == 'bay-area' ? 'active' : '')} onClick={() => {
                    setCurrentList('bay-area')
                  }} href="#">Bay Area</a>
                  <a className={"btn border-radius m1 " + (currentList == 'seattle' ? 'active' : '')} onClick={() => {
                    setCurrentList('seattle')
                  }} href="#">Seattle</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='press-title'>
          <h1>News & Press</h1>
        </div>
        <div className="grid-container-portfolio-press">
          {/* {json.filter((e) => e.Key == currentList).map((item, key) => { */}
          {data.map(({ img, title, url }, key) => {
            return (
              <a key={key} href={url} target="_blank"><div className="grid-item p-grid portfolio-living" style={{ cursor: 'pointer' }}>
                <img src={img} alt='' width="300px" style={{ borderRadius: 10 }} />
                <p>{title}</p>
              </div>
              </a>
            )
          })}
          <div className='list-item' />
          <div className='list-item' />
          <div className='list-item' />
          <div className='list-item' />
        </div>
        <div className='pagination'>
          <Pagination count={2} classes={{ icon: { width: '200px' }, page: {} }} onChange={onChange} />
        </div>
      </div>
      <Footer hiddden={true} />
    </>
  );
};

export default Portfolio;