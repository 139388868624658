import './Home.css';
import {
  Navbar,
  Footer,
} from "../../components";

const ourList = [
  { title: 'Proven Superior Return', img: require('../../assets/home/our-1.png') },
  { title: 'Vertically Intergrated Development ', img: require('../../assets/home/our-2.png') },
  { title: 'Well Connected Elite Team', img: require('../../assets/home/our-3.png') },
  { title: 'Community Oriented Design', img: require('../../assets/home/our-4.png') },
  { title: 'Tech & Data Driven Operation', img: require('../../assets/home/our-5.png') },
  { title: 'Next-gen Living Experience', img: require('../../assets/home/our-6.png') },
]

const partners = [
  require('../../assets/home/forbes.jpeg'),
  require('../../assets/home/tech.jpeg'),
  require('../../assets/home/crunch.png'),
  require('../../assets/home/los.png'),
  require('../../assets/home/builin.png'),
  require('../../assets/home/36k.png'),
]

const homeMv = 'https://tripalink-public.s3.us-east-2.amazonaws.com/home.MOV'
const Home = () => {
  return (
    <>
      <Navbar navnav={true} />
      <div className="hm">
        <div className='hm-box' />
        <section className="center">

          <div className="container home center c-white">

            <h1>Reinventing Home</h1>
            <h3 className="p-2 subtitle__home">Provide enjoyable living experiences through technology and community building</h3>

          </div>

          <a href="mailto:realestate@tripalink.com" className="c-white button">Contact Us</a>

        </section>
      </div>
      <div className="wwa">
        <div className="row-space status center p-2">
          <div className="p-2 wrong">
            <h1>90+</h1>
            <h2>Projects in Portfolio</h2>
          </div>
          <div className="p-2 wrong">
            <h1>$700+ MN</h1>
            <h2>Gross Development Value</h2>
          </div>
          <div className="p-2 wrong">
            <h1>30+</h1>
            <h2>Communities in Operation</h2>
          </div>
        </div>

        <div className="container ptb">
          <div className="row-space p-2 ptb">
            <div className="column">
              <p style={{ color: "#e74f4f", fontSize: "15px" }}>WHO WE ARE</p><br />
              <h1 className="wwa__text__mobile">Community <br /> Oriented Residential Brand of Tomorrow for Gen-Z and Millennials </h1>
            </div>
            <div className="column center relative" style={{ marginTop: 10 }}>
              <video autoPlay muted style={{ borderRadius: '12px' }} width="100%" height="auto" controls>
                <source src={homeMv} type="video/mp4" />
                {/* <source src="movie.ogg" type="video/ogg" /> */}
              </video>
              {/* <img src={require('../../assets/ubicacion.jpg')} style={{ top: "-100px" }} /> */}
            </div>
          </div>
          {/* <div className="row-space p-2 ptb">
            <div className="column center relative order"> */}
          {/* <img src={require('../../assets/estadistica.jpg')} /> */}
          {/* </div> */}
          {/* <p className="vmobile" style={{display:"none"}}>WHO WE ARE</p>*/}<br />
          {/* <div className="column align-center">
              <h1>Exponential Portfolio Growth</h1>
            </div>
          </div> */}
        </div>

        <div className="row-space ptb home-row">
          <div className="align-center">
            <h1>Our Co-living</h1>
          </div>
        </div>

        <div className="grid-container container co-living">
          {ourList.map(({ title, img }) => (
            <div key={title} className="grid-item living ">
              <img src={img} width="100%" />
              <p>{title}</p>
              {/* <p className="p-xs">By including amenities like common kitchen and co-working space, we create more opportunities for our tenants to engage with each other more organically. We also partnered with nonprofit Apartmentlife, so we can plan more exciting events for our tenants</p> */}
            </div>

          ))}
          {/* <div className="grid-item living ">
            <img src={require('../../assets/event.png')} width="100%" />
            <p>Community</p>
            <p className="p-xs">By including amenities like common kitchen and co-working space, we create more opportunities for our tenants to engage with each other more organically. We also partnered with nonprofit Apartmentlife, so we can plan more exciting events for our tenants</p>
          </div>

          <div className=" grid-item living">
            <img src={require('../../assets/maaya.png')} width="100%" />
            <p>Convenience</p>
            <p className="p-xs">Most of our bedrooms are fully furnished with utilities already set up. Just bring your suitcase, and you can move in right away. Our locations are strategically located, so it's walkable to transit, restaurants, and grocery stores</p>
          </div>

          <div className=" grid-item living">
            <img src={require('../../assets/perks.png')} width="100%" />
            <p>Economical</p>
            <p className="p-xs">By utilizing an efficient design layout, we are able to rent out our furnished private suites 30% less than a studio apartment</p>
          </div> */}

        </div>
        <div className='parentrs container'>
          {partners.map(item => <img src={item} />)}
        </div>
      </div>
      <Footer hiddden={true} />
    </>
  );
};

export default Home;